.zee_list {
  &-action {
    width: 100%;
    margin-bottom: 16px;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      &-extra {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
      }
    }

    &-content {
      padding: 10px;
      border: 1px solid #eeeff5;
      margin-top: 10px;
      border-radius: 10px;

      &-main {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      }

      &-footer {
        display: flex;
        justify-content: end;
        gap: 20px;
      }
      // justify-content: end;
    }
  }

  .expand-container {
    padding: 13px 50px;

    .expand-header {
      display: flex;

      .header-item {
        color: #5b33ff;
        width: 200px;
        font-weight: bolder;
      }
    }

    .expand-content {
      .content-item {
        display: flex;

        .item-status {
          width: 200px;
          padding: 10px 0;

          .icon-tick {
            font-size: 23px;
            svg {
              fill: #028701;
            }
          }

          .icon-dot {
            font-size: 23px;
            svg {
              fill: #f3bb1c;
            }
          }
        }

        .item-date {
          width: 200px;
          padding: 10px 0;
        }

        .item-activity {
          padding: 10px 0;
        }
      }
    }
  }

  table {
    thead {
      tr {
        th {
          font-weight: bolder;
          background-color: #f5f7fa;
        }
      }
    }

    tbody {
      tr {
        cursor: pointer;
        td {
          .status-container {
            display: flex;
            align-items: center;
            padding: 5px 20px;
            border: 1px solid gray;
            border-radius: 5px;
            width: fit-content;

            &.danger {
              background-color: #ffebea;
              border-color: #fcc7c6;
              color: #f03738;
            }

            &.success {
              background-color: #cfedd8;
              border-color: #b2e4b9;
              color: #028701;
            }

            &.info {
              background-color: #fdf1d2;
              border-color: #f8d677;
              color: #9b7403;
            }

            &.warning {
              background-color: #ffebea;
              border-color: #fcc7c6;
              color: #f03738;
            }
          }

          .file-item-container {
            margin: 5px;
          }
        }
      }
    }
  }
}

.react-json-view {
  min-height: 100%;
  border-radius: 4px;
}

.custom-modal_upload {
  max-width: 1000px !important;
  width: 100% !important;
}

.custom-modal_status {
  max-width: 1000px !important;
  width: fit-content !important;
}
